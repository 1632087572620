<script lang="ts" setup>
const localePath = useLocalePath()

withDefaults(
  defineProps<{
    type?: 'main' | 'footer'
  }>(),
  {
    type: 'main',
  },
)
</script>
<template>
  <NuxtLink :class="['logo', `logo-${type}`]" :to="localePath('/')">
    <img src="/img/logo.svg" class="image" loading="eager" alt="Careum Logo" />
  </NuxtLink>
</template>
<style scoped lang="scss">
.logo {
  display: flex;
  align-items: center;

  &-main {
    --width: 95px;
    @include breakpoint(ds) {
      --width: 140px;
    }
  }
  &-footer {
    --width: 110px;
  }

  img {
    width: var(--width);
  }
}
</style>
